		
<template>
	<div class="outer-wrapper">
		
		<Loader :loading="isLoading"/>
		
		<div class="success-or-fail" v-if="!isLoading">
			
			<div id="top-nav"></div>
			
			<div class="inner-wrapper">

				<div class="heading-section">
					<h1 class="heading3">{{ heading }}</h1>
				</div>

				<TripItem 
					:id="trip_item_id"
					:tripID="trip_id"
					:type="item.type"
					:name="item.name"
					:price="item.alerts.all[0].data.new_price"
					:date="item.date"
					:bookingStatus="item.bookingStatus"
					:alert="alert"
					:data="item.data"

					:showContinueButton="false"

					class="item"
				/>

				<button class="button confirm" @click="confirm()">Continue</button>
				<button class="button return" @click="back()">Back</button>
			</div>

			<div class="bottom inner-wrapper">
				<div><strong>or</strong></div>
				<div class="new-flight">Select new flight</div>
				<button class="button" @click="replaceFlight()">Search</button>
			</div>
		</div>
	</div>
</template>

<script>
import router from '@/router';
import { mapState, mapActions, mapGetters } from 'vuex';
import TripItem from "@/components/trips/TripItem.vue";
import Loader from '@/components/Loader.vue';

export default {
	props: {
		heading: {
			type: String,
		},
	},
	data() {
		return {
			trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : null,
			trip_item_id: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,

			isLoading: false,
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
		item() {
			return this.trip.tripItems[this.trip_item_id];
		},
		alert() { // Override the alert to have some more specific/custom messages
			if(this.item.alerts.all.length > 0) {
				let alert = { 
					"alert_code": "yellow", 
					"alert_type": "price", 
					"main_message": "The old ticket price was " + this.item.alerts.all[0].data.old_price, 
					"secondary_message": "The price has changed", 
					"description": "Price has changed. Please confirm the new price, or select a different flight.", 
					"data": { 
						"old_price": this.item.alerts.all[0].data.old_price, 
						"new_price": this.item.alerts.all[0].data.new_price 
					} 
				};
				return alert;
			}
			return false;
			
		}
	},
	methods: {
		...mapActions({
			checkFlightsValidity: "trip/checkFlightsValidity",
			confirmFlightsChange: "trip/confirmFlightsChange",
			alertSuccess: "alert/success",
			alertError: "alert/error"
		}),
		replaceFlight() {

			// TODO: could fetch all relevant details for search based on this existing trip item, and send them straight to the search results using this data?

			// Navigate to the search process with query param for the trip item to replace
			router.push({
				name: 'Flights Locations',
				query: {
					trip_id: this.trip_id,
					trip_item_id: this.trip_item_id,
				}
			})
		},
		back() {
			router.go(-1); // We could get to this page from trip page or trip item page. We want to go back to the same one.
			// router.push({
			// 	name: 'Saved Flights Details',
			// 	params: {
			// 		trip_id: this.trip_id,
			// 		trip_item_id: this.trip_item_id,
			// 	}
			// })
		},
		confirm() {
			this.isLoading = true;
			this.confirmFlightsChange({ tripID: this.trip_id, tripItemID: this.trip_item_id })
				.then(response => {
					this.alertSuccess('Flight price confirmed.');
					this.back();
					// router.push({
					// 	name: 'Saved Flights Details',
					// 	params: {
					// 		trip_id: this.trip_id,
					// 		trip_item_id: this.trip_item_id,
					// 	}
					// })
					// this.isLoading = false; // Does including this cause a visual flash of the current page, before the redirect?
				})
				.catch(error => {
					this.$rollbar.error(error);
					this.isLoading = false;
					this.alertError(error);
				});

		}
	},
	components: {
		TripItem,
		Loader
	},
	created () {
		if(!this.trip.tripID) {
			this.back();
		}
	},
};
</script>	

<style scoped>
	.success-or-fail {
		text-align: center;
	}
	.success-or-fail .heading3 {
		padding-bottom: 42px;
	}
	.success-or-fail button {
		margin: 0 5px;
		margin-bottom: 15px;
	}
	.success-or-fail button.confirm {
		background-color: #E9C50B;
		margin-top: 30px;
	}
	.success-or-fail button.return {
		background-color: white;
		border: 1px solid #118AB2;
		color: #118AB2;
		font-weight: 500;
	}
	.success-or-fail .bottom {
		box-sizing: border-box;
		text-align: center;
	}
	.success-or-fail .bottom .new-flight {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.success-or-fail .item {
		text-align: left;
	}
</style>
		